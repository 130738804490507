



















import { expressBaseData } from '@/api/order';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  @Prop()
  innerData!: expressBaseData;

  get expressList(): { time: string; status: string }[] {
    return JSON.parse(this.innerData.expressList);
  }
}
