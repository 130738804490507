































import { expressBaseData, expressDeliveryStatus } from '@/api/order';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  @Prop()
  innerData!: expressBaseData;

  stateList = [
    {
      name: '已发货',
      state: expressDeliveryStatus.EXPRESS_COLLECT
    },
    {
      name: '运输中',
      state: expressDeliveryStatus.EXPRESS_ON_THE_WAY
    },
    {
      name: '派件中',
      state: expressDeliveryStatus.EXPRESS_DELIVERY
    },
    {
      name: '已签收',
      state: expressDeliveryStatus.EXPRESS_SIGN_FOR
    }
  ];

  get deliveryStatus() {
    return this.innerData.deliveryStatus;
  }

  get expressList() {
    return JSON.parse(this.innerData.expressList);
  }
}
