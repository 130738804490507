import request, { baseQueryByPage } from '@/utils/request';
import { IRequestResult } from '../type/tool';
import { IBaseOrder, IBaseStand, IBaseProduct, GetPromiseReturn } from '../type/base';

export const getOrderList = baseQueryByPage<
  Partial<
    Pick<IBaseStand, 'skuName'> &
      Pick<IBaseProduct, 'spuName'> &
      Pick<IBaseOrder, 'orderCode' | 'orderState' | 'payState' | 'deliverState' | 'payType'> & {
        /* 充值账号 */
        rechargeAccount?: string;
        /* 支付时间开始 */
        payTimeBegin?: string;
        /* 支付时间结束 */
        payTimeEnd?: string;
        /* 发货时间开始 */
        deliverTimeBegin?: string;
        /* 发货时间结束 */
        deliverTimeEnd?: string;
      }
  >,
  Pick<
    IBaseOrder,
    | 'orderCode'
    | 'skuImgUrl'
    | 'skuName'
    | 'payType'
    | 'spuType'
    | 'actualPrice'
    | 'actualPoint'
    | 'spuName'
    | 'rechargeAccount'
    | 'commodityNum'
    | 'orderState'
    | 'payState'
    | 'deliverState'
    | 'orderExtends'
  >
>('/order/order_record/list');

export type OrderListItemData = GetPromiseReturn<typeof getOrderList>['payload']['records'][number];

/* 查询列表单个项 */
export function getOrderListItem(orderCode: OrderListItemData['orderCode']): Promise<IRequestResult<OrderListItemData>> {
  return request.get(`/order/order_record/single/${orderCode}`, { hideMsg: true });
}

// 取消订单
export function cancelOrder(orderCode: string) {
  return request.post(`/order/order_record/cancel/${orderCode}`, {}, { msgText: '取消成功', loading: true, loadingText: '' });
}

/* 商品详情 */
export type IDetailData = Omit<IBaseOrder, 'cardList'> & {
  discounts: {
    detailId: number;
    discountsDescription: string;
    discountsPrice: number;
    discountsType: number;
  }[];
  originalPrice: number;
};
export function getOrderDetail(orderCode: IBaseOrder['orderCode']): Promise<IRequestResult<IDetailData>> {
  return request.get(`/order/order_record/detail/${orderCode}`, { hideMsg: true });
}

export enum expressDeliveryStatus {
  /* 快递揽收 */
  EXPRESS_COLLECT = '0',
  /* 快递在途中 */
  EXPRESS_ON_THE_WAY = '1',
  /* 快递在派件 */
  EXPRESS_DELIVERY = '2',
  /* 快递签收 */
  EXPRESS_SIGN_FOR = '3',
  /* 快递派件失败 */
  EXPRESS_DELIVERY_FAIL = '4',
  /* 快递疑难件 */
  EXPRESS_DIFFICULT = '5',
  /* 快递退件 */
  EXPRESS_REJECTION = '6'
}
export interface expressBaseData {
  /** 快递单号 */
  expressId: string;
  /** 订单号 */
  orderCode: string;
  /** 快递类型 */
  expressType: string;
  /** 物流详情信息 */
  expressList: string;
  /** 快递状态 0：快递收件(揽件)1.在途中 2.正在派件 3.已签收 4.派送失败 5.疑难件 6.退件签收 */
  deliveryStatus: expressDeliveryStatus;
  /** 是否签收 */
  issign: boolean;
  /** 快递公司名称 */
  expName: string;
  /** 快递官网 */
  expSite: string;
  /** 快递电话 */
  expPhone: string;
  /** 快递员或快递站 */
  courier: string;
  /** 快递员电话 */
  courierPhone: string;
  /** 运输时长 */
  takeTime: string;
  /** 快递logo */
  logo: string;
  /** 创建时间 */
  createTime: string;
  /** 更新时间 */
  updateTime: string;
  /** 最后状态 */
  lastStatus: string;
  /** 最后更新时间 */
  lastTime: string;
}

/* 根据物流id查询单个物流信息 (没屁用) */
export function getExpressRecord(orderCode: expressBaseData['expressId']): Promise<IRequestResult<expressBaseData>> {
  return request.get(`/order/expressRecord/info/${orderCode}`, { hideMsg: true });
}

/* 查询订单下所有物流信息（查物流统一用这个） */
export function getExpressRecordList(orderCode: IBaseOrder['orderCode']): Promise<IRequestResult<expressBaseData[]>> {
  return request.get(`/order/expressRecord/list/${orderCode}`, { hideMsg: true });
}

/* 查询卡密 */
export function getCardPwd(
  orderCode: IBaseOrder['orderCode']
): Promise<IRequestResult<Pick<IBaseOrder, 'orderCode' | 'spuId' | 'skuName' | 'spuName' | 'commodityNum' | 'skuImgUrl' | 'orderTime' | 'spuDecr' | 'cardList'>>> {
  return request.get(`/order/order_record/cardPassword/${orderCode}`, { hideMsg: true });
}

/* 通过订单号获取预支付订单号 */
export function getPrepay(data: IBaseOrder['orderCode']): Promise<
  IRequestResult<{
    /* 预支付订单号 */
    prepayId: string;
  }>
> {
  return request.post(`/order/order_record/getPrepayId/${data}?detailPayType=h5`, {}, { hideMsg: true, loading: true, loadingText: '' });
}

/* 确认收货 */
export function confirmReceipt(orderCode: IBaseOrder['orderCode']): Promise<IRequestResult> {
  return request.post(`/order/order_record/confirmReceipt/${orderCode}`, {}, { msgText: '收货成功' });
}
