
































import { expressBaseData, getExpressRecordList } from '@/api/order';
import { IBaseOrder } from '@/api/type/base';
import { Vue, Component } from 'vue-property-decorator';
import step from './comm/Step.vue';
import StepVertical from './comm/StepVertical.vue';
@Component({
  name: '',
  components: { step, StepVertical }
})
export default class extends Vue {
  list: (expressBaseData & { name?: string })[] = [];

  active = 0;

  get orderCode() {
    return this.$route.params.orderCode as string;
  }

  get orderImg() {
    return this.$route.query.orderImg as string;
  }

  async getExpressRecord(orderCode: IBaseOrder['orderCode']) {
    const { payload } = await getExpressRecordList(orderCode);

    this.list = payload;
    this.list.forEach((item: any, index) => {
      item.name = '物流' + (index + 1);
    });
  }
  created() {
    this.getExpressRecord(this.orderCode);
  }
}
